import { createContext } from "react";

export const userSchema = {
  username: (value) => true,
  token: (value) => true,
  name: (value) => true,
};

userSchema.username.required = true;
userSchema.token.required = true;
userSchema.name.required = true;

export const userContext = createContext({});
