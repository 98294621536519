import { Fragment, lazy, Suspense, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import yaml from "js-yaml";
import { jwtDecode } from "jwt-decode";
import validate from "tools/validate";
import { userContext, userSchema } from "tools/userContext";
import { gameContext } from "tools/gameContext";
import { newClient } from "tools/backendContext";
import { setSession, getSession } from "tools/localStorage";
import { Route, Routes, Router, Navigate } from "react-router-dom";
import Loading from "routes/loading/loadingPage";

const GamePage = lazy(() => import("routes/game/gamePage"));
const LeaderBoardPage = lazy(() =>
  import("routes/leaderboard/leaderBoardPage")
);
const GameListPage = lazy(() => import("routes/gameList/gameListPage"));
const MainPage = lazy(() => import("routes/main/mainPage"));
const ErrorPage = lazy(() => import("routes/error/errorPage"));
const SignUpPage = lazy(() => import("routes/signUp/SignUpPage"));

function everythingAfterFirstDash(string) {
  var index = string.indexOf("-");
  if (index !== -1) {
    return string.substring(index + 1);
  } else {
    return "";
  }
}

function App() {
  const [searchParams] = useSearchParams();
  const [user, setUser] = useState({});
  const [games, setGames] = useState({});
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [smallTilesList, setSmallTilesList] = useState([]);
  const [mediumTilesList, setMediumTilesList] = useState([]);
  const [bigTilesList, setBigTilesList] = useState([]);
  const [hasToken, setHasToken] = useState(false);
  const [token, setToken] = useState("");
  const titles = [];

  useEffect(() => {
    if (searchParams.has("token")) {
    if (!searchParams.has("token")) {
      console.log("token not provided");
      setHasToken(false);
      setLoading(false);
      return;
    } else {
      setHasToken(true);
      setToken(searchParams.get("token"));
    }

    let u = {
      token: searchParams.get("googleToken"),
      username: "gamer",
      name: "Gamer",
    };

    if (token.length !== 0) {
      try {
        const decoded = jwtDecode(token);
        u.username = everythingAfterFirstDash(decoded.usn);
        u.name = decoded.vrs.name;
      } catch (e) {
        console.log(e);
        setError(true);
        setLoading(false);
        return;
      }
    }

    const errors = validate(u, userSchema);

    if (errors.length > 0) {
      console.log(errors);
      setError(true);
      setLoading(false);
      return;
    } else {
      setUser(u);
    }
    }

    fetch(
      process.env.REACT_APP_STORAGE_URL +
        process.env.REACT_APP_CONFIG_BUCKET +
        "/config-liom.yml",
      {}
    )
      .then((res) => res.blob())
      .then((blob) => blob.text())
      .then((s) => {
        try {
          let config = yaml.load(s);
          setGames(config.games);
          setSmallTilesList(config.small_tiles);
          setMediumTilesList(config.medium_tiles);
          setBigTilesList(config.big_tiles);
          setLoading(false);
        } catch (e) {
          console.log(e);
          setError(true);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setError(true);
        setLoading(false);
      });
  }, [searchParams, token]);

  titles.push(smallTilesList, mediumTilesList, bigTilesList);

  if (error) {
    return <ErrorPage />;
  }

  if (loading) {
    return <Loading />;
  }

  return (
    // <ThemeProvider theme={theme}>
    <Fragment>
      <userContext.Provider value={user}>
        <gameContext.Provider value={games}>
          <Suspense fallback={<Loading />}>
            <Routes>
              {/* <Route
                path="/"
                element={
                  hasToken ? <MainPage titles={titles} /> : <SignUpPage />
                }
              /> */}
              {/* <Route path="/" element={<BackButtonHandler />} /> */}
              <Route path="/" element={<MainPage titles={titles} />} />
              <Route path="/signup" element={<SignUpPage />} />
              <Route
                path="/game/:game_id/:leaderboard_id"
                element={<GamePage />}
              />
              <Route path="/board" element={<GameListPage />} />
              <Route path="/board/:game_id" element={<LeaderBoardPage />} />
              <Route
                path="*"
                element={
                  <main style={{ padding: "1rem" }}>
                    <p>There's nothing here!</p>
                  </main>
                }
              />
            </Routes>
          </Suspense>
        </gameContext.Provider>
      </userContext.Provider>
    </Fragment>
    // </ThemeProvider>
  );
}

export default App;
