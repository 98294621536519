import {css} from "@emotion/css";
import Spinner from "components/loading/spinner/spinner";

const Loading = () => <div className={css`
          width: 100%;
          height: 100%;
          background: white;
          grid-row-start: 1;
          grid-column-start: 1;
          justify-self: stretch;
          align-self: stretch;
          display: flex;
          justify-content: center;
          align-items: center;
        `}><Spinner color="#000000"/></div>

export default Loading;